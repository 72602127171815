import React from 'react';

import SEO from '../../components/SEO';
import DescriptionSection from '../../components/pages/services/planning/DescriptionSection';
// import GetStartedSection from '../../components/pages/GetStartedSection';
import ConfigurationsSection from '../../components/pages/services/planning/ConfigurationsSection';

const seo = {
  title: 'Organism System Strategic Planning',
  description: '',
};
const Registry = () => {
  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <DescriptionSection />
      {/* <ConfigurationsSection /> */}
      {/* <GetStartedSection /> */}
    </>
  );
};

export default Registry;
