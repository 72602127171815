import React from 'react';
import styled from '@emotion/styled';

import { media } from '../../../../utils/emotion';
import DefaultGridSection from '../../DefaultGridSection';
import registryGraphics from '../../../../assets/product/registry-hero.svg';
import registryBox from '../../../../assets/product/registry-box.svg';
import registryBoxMobile from '../../../../assets/product/registry-box-mobile.svg';
import Text from '../../../Text';
import CheckedListItem from '../../../CheckedListItem';

const SpacingContainer = styled('div')`
  margin-top: 9rem;
`;

const ImageBoxContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;

const ImageBox = styled('div')`
  display: flex;
  margin-bottom: 5rem;
  background: transparent url(${registryBoxMobile}) no-repeat center center;
  background-size: contain;
  min-height: 48rem;
  ${media.tablet`
    margin-bottom: 9.6rem;
    background: transparent url(${registryBox}) no-repeat center center;
    background-size: contain;
    min-height: 27rem;
  `}
  width: 100%;
  max-width: 110rem;
`;

const DescriptionSection = () => {
  return (
    <SpacingContainer>
      <DefaultGridSection
        image={registryGraphics}
        direction="imageOnRight"
        title="Business Strategic Planning"
        subtitle="Do you have an idea, or have you encountered some challenge?"
      >
        <Text>
          Achieving Organizational Excellence Begins by Developing a Strong Strategy.
          At Organism, we believe in striving to be the best. Our operational and strategic planning services 
          help organizations achieve success by working to align organizational behaviors with strategic goals. 
        </Text>
        <CheckedListItem>
          Formulation
          <Text color="caption">Understand your key goals</Text>
        </CheckedListItem>
        <CheckedListItem>
          Customized human capital services
          <Text color="caption">
           get your the most out of your teams
          </Text>
        </CheckedListItem>
        <CheckedListItem>
          Develop Intelligent Workflows
          <Text color="caption">
           step by step playbook to reach your goals
          </Text>
        </CheckedListItem>
      </DefaultGridSection>
      {/* <ImageBoxContainer>
        <ImageBox />
      </ImageBoxContainer> */}
    </SpacingContainer>
  );
};

DescriptionSection.propTypes = {};

export default DescriptionSection;
